<template>
<div class="content_body Supplier" v-loading="loading">
    <div class="nav_header">
      <el-row>
          <el-col :span="20">
              <el-form :inline="true" size="small" :model="searchSupplierFrom">
                  <el-form-item label="供应商">
                      <el-input v-model="searchSupplierFrom.Name" placeholder="输入供应商名称、手机号搜索" clearable @keyup.enter.native="handleSearch" @clear="handleSearch"></el-input>
                  </el-form-item>
                  <el-form-item label="有效性" >
                    <el-select v-model="searchSupplierFrom.Active" placeholder="请选择有效性"  @change="handleSearch"
                  @clear="handleSearch" clearable>
                      <el-option label="是" :value="true"></el-option>
                      <el-option label="否" :value="false"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                      <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
                  </el-form-item>
              </el-form>
          </el-col>
          <el-col :span="4" class="text_right">
              <el-button type="primary" @click="addSupplierClick" size="small" v-prevent-click>新增供应商</el-button>
          </el-col>
      </el-row>
    </div>

    <el-table class="martp_10" size="small" :data="supplierList" tooltip-effect="light">
        <el-table-column prop="Name" label="供应商名称"></el-table-column>
        <el-table-column prop="ContactPersonName" label="联系人" ></el-table-column>
        <el-table-column prop="ContactPersonMobile" label="联系人手机号" ></el-table-column>
        <el-table-column prop="Remark" label="备注" show-overflow-tooltip></el-table-column>
        <el-table-column prop="Active" label="有效性" :formatter="formatStatus"></el-table-column>
        <el-table-column label="操作" width="80">
            <template slot-scope="scope">
                <el-button type="primary" size="small" @click="editSuoolierInfoClick(scope.row)" v-prevent-click>编辑</el-button>
            </template>
        </el-table-column>
    </el-table>

    <div class="pad_15 text_right">
      <el-pagination
        background
        v-if="paginations.total > 0"
        @current-change="handleCurrentChange"
        :current-page.sync="paginations.page"
        :page-size="paginations.page_size"
        :layout="paginations.layout"
        :total="paginations.total"
      ></el-pagination>
    </div>


    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="980px" @closed="cancelAddSubmitSupplierClick('addSupplierFrom')">
      <div class="tip marbm_10" style="margin-top:0">基本信息</div>
      <el-form :model="addSupplierFrom" label-width="100px" size="small" class="supplierInfoFrom" :rules="fromRules" ref="addSupplierFrom">
        <el-row>
          <el-col :span="12">
            <el-form-item  label="供应商名称" prop="Name" >
              <el-input v-model="addSupplierFrom.Name" placeholder="请输入供应商名称" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  label="公司电话" >
              <el-input v-model="addSupplierFrom.SupplierTelePhone" placeholder="请输入供应商公司电话" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  label="联系人" >
              <el-input v-model="addSupplierFrom.ContactPersonName" placeholder="请输入供应商联系人" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  label="手机号码" prop="ContactPersonMobile">
              <el-input v-model="addSupplierFrom.ContactPersonMobile" maxlength="11" placeholder="请输入供应商联系人手机号码" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  label="地址" >
              <el-cascader
                clearable
                placeholder="请选择省 / 市 / 区"
                size="small"
                :options="options"
                v-model="addSupplierFrom.selectedOptions"
                @change="handleChange">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  label="详细地址" >
              <el-input v-model="addSupplierFrom.AddressDetail" placeholder="请输入供应商公司详细地址" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  label="有效性" >
            <el-radio-group v-model="addSupplierFrom.Active" >
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  label="备注" >
              <el-input type="textarea" style="width:300px" :autosize="{ minRows: 1, maxRows: 3 }" v-model="addSupplierFrom.Remark" placeholder="请输入备注信息" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      <div class="tip marbm_10" style="margin-top:0">其他信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item  label="微信号" >
              <el-input v-model="addSupplierFrom.WeiXin" placeholder="请输入供应商微信号" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  label="邮箱" prop="Email">
              <el-input v-model="addSupplierFrom.Email" placeholder="请输入供应商邮箱" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item  label="传真" >
              <el-input v-model="addSupplierFrom.Fax" placeholder="请输入供应商传真号" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <el-button @click="cancelAddSubmitSupplierClick('addSupplierFrom')" size="small" v-prevent-click>取 消</el-button>
          <el-button type="primary" @click="addSubmitSupplierClick('addSupplierFrom')" :loading="modalLoading" size="small"  v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import APISupplier from  "@/api/PSI/Purchase/supplier";
import validate from "@/components/js/validate.js";
import {regionData} from 'element-china-area-data'


export default {
 name: 'Supplier',
/**  Vue 实例的数据对象**/
  data() {
    return {
      dialogVisible:false,
      loading:false,
      modalLoading:false,
      isEditState:false,
      dialogTitle:"",
      supplierList:[],
      searchSupplierFrom:{
        Name:"",
        Active:true,
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper" // 翻页属性
      }, //需要给分页组件传的信息
      addSupplierFrom:{
        Name : "",
        ContactPersonName : "",
        ContactPersonMobile : "",
        SupplierTelePhone : "",
        ProvinceCode : "",
        CityCode : "",
        AreaCode : "",
        selectedOptions:[],
        AddressDetail : "",
        WeiXin : "",
        Email : "",
        Fax : "",
        Active:true,
        Remark : ""
      },
      options: regionData,
      fromRules:{
        Name:[{required: true, message: '请输入供应商名称', trigger: 'blur' }],
        ContactPersonMobile:[ { required: false, validator: validate.validPhoneNumber, trigger: 'change', }],
        Email:[ {required: false, validator: validate.validEmail, trigger: 'change', }]
      }
    }
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**  table 数据格式化  */
      /**  状态显示转换  */
      formatStatus: function(row) {
        return row.Active ? "是" : "否";
      },

    /**  点击事件  */
      /**  列表搜索  */
      handleSearch(){
        var that = this
        that.paginations.page = 1;
        that.getSupplierListNetwork()
      },
      /**  修改页码  */
      handleCurrentChange(page){
        var that = this;
        that.paginations.page = page;
        that.getSupplierListNetwork()
      },
      /**  添加供应商   */
      addSupplierClick(){
        var that = this
        that.dialogTitle = "新增供应商"
        that.dialogVisible = true
        that.isEditState = false
        that.addSupplierFrom = {
          Name : "",
          ContactPersonName : "",
          ContactPersonMobile : "",
          SupplierTelePhone : "",
          ProvinceCode : "",
          CityCode : "",
          AreaCode : "",
          selectedOptions:[],
          AddressDetail : "",
          WeiXin : "",
          Email : "",
          Fax : "",
          Active:true,
          Remark : ""
        }
      },
      /**  编辑供应商信息  */
      editSuoolierInfoClick(row){
        var that = this
        that.dialogTitle = "编辑供应商"
        that.isEditState = true
        that.addSupplierFrom = {
          ID:row.ID,
          Name : row.Name,
          ContactPersonName : row.ContactPersonName,
          ContactPersonMobile : row.ContactPersonMobile,
          SupplierTelePhone : row.SupplierTelePhone,
          ProvinceCode : row.ProvinceCode,
          CityCode : row.CityCode,
          AreaCode : row.AreaCode,
          selectedOptions:[row.ProvinceCode,row.CityCode,row.AreaCode,],
          AddressDetail : row.AddressDetail,
          WeiXin : row.WeiXin,
          Email : row.Email,
          Fax : row.Fax,
          Active:row.Active,
          Remark : row.Remark
        }
        that.dialogVisible = true
      },
      /**  修改省市区   */
      handleChange (value) {
        var that = this
        that.addSupplierFrom.selectedOptions = value
        that.addSupplierFrom.ProvinceCode = value[0]
        that.addSupplierFrom.CityCode = value[1]
        that.addSupplierFrom.AreaCode = value[2]
      },
      /**  关闭弹窗  */
      cancelAddSubmitSupplierClick(){
        var that = this
        that.$refs["addSupplierFrom"].resetFields();
        that.dialogVisible = false
      },
      
      /**  保存 供应商  */
      addSubmitSupplierClick(){
        var that = this
        that.$refs["addSupplierFrom"].validate((valid)=>{
          if(valid){
            that.modalLoading = true
              if(that.isEditState){
                that.setSupplierUpdateNetwork(that.addSupplierFrom);
              }
              else{
                that.saveSupplierCreateNetwork(that.addSupplierFrom);
              }
          }
        })

      },

    /**  网络请求  */
      /**  获取供应商列表  */
      getSupplierListNetwork: function() {
        var that = this;
        that.loading = true;
        var params = {
          PageNum:that.paginations.page,
          Name: that.searchSupplierFrom.Name,
          Active: that.searchSupplierFrom.Active
        };

        APISupplier.getSupplierList(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.supplierList = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
      },
      /**  添加供应商  */
      saveSupplierCreateNetwork: function(params) {
        var that = this;
        APISupplier.getSupplierCreate(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.dialogVisible = false
            that.getSupplierListNetwork()
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
            that.modalLoading = false
        });
      },
      /**  跟新供应商  */
      setSupplierUpdateNetwork: function(params) {
        var that = this;
        APISupplier.getSupplierUpdate(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.dialogVisible = false
            that.getSupplierListNetwork()
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
            that.modalLoading = false
        });
      },

  },

  /**  实例被挂载后调用  */
  mounted() {
    var that = this
    that.getSupplierListNetwork()
  }
}
</script>

<style lang="scss">
.Supplier{
  .supplierInfoFrom{
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
  }

}

</style>
