/**
 * Created by preference on 2020/09/08
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 列表  */
  getSupplierList: params => {
    return API.POST('api/supplier/list', params)
  },
  /**  创建  */
  getSupplierCreate: params => {
    return API.POST('api/supplier/create', params)
  },
  /** 更新  */
  getSupplierUpdate: params => {
    return API.POST('api/supplier/update', params)
  },
}